













































import {Component, Vue} from "vue-property-decorator";
import PersonDTO from "@/dto/person/PersonDTO";
import PersonService from "@/services/PersonService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import PayrollService from "@/services/payroll/PayrollService";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import {namespace} from "vuex-class";
import FileUpload from "@/components/common/FileUpload.vue";
import PayrollDocumentActionModal from "@/components/util/DocumentActionModal.vue";
import {CounterpartyType} from "@/constants/CounterpartyType";
import Form1099DTO from "@/dto/payroll/Form1099DTO";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
	components: {FileUpload}
})
export default class Individual1099Forms extends Vue {

	@AppModule.State
	private loading!: boolean;

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	@Auth.Getter("hasAdministrativePrivileges")
	private hasAdministrativePrivileges!: boolean;

	private message = "";

	private successful = true;

	private forms: Array<Form1099DTO> = [];

	private employee = new PersonDTO(); // mock to prevent continuous null checking

	mounted(){
		this.loadPerson().then(
			() => this.loadForms()
		);
	}

	loadPerson(){
		this.startLoading();
		return PersonService.getById(Number.parseInt(this.$route.params.personId, 10)).then(
			response => {
				this.successful = true;
				this.stopLoading();
				console.log(response.data)
				this.employee = response.data;
			},
			error => {
				this.stopLoading();
				this.successful = false;
				this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
			}
		);
	}

	loadForms(){
		this.startLoading();
		return PayrollService.getForms1099(CounterpartyType.PERSON, this.employee.id as number).then(
			(response) => {
				this.successful = true;
				this.forms = response.data;
			},
			(error) => {
				this.successful = false;
				this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
			}
		).then(() => this.stopLoading());
	}

	onDocumentClick(document: FileMetaDTO) {
		Vue.prototype.$docUtils.download(document.id);
	}

	deleteDocument(document: FileMetaDTO) {
		this.message = "";
		this.startLoading();
		PayrollService.deleteDocument(document.id, 'PAYROLL_FILE').then(
			response => {
				this.message = "Deleted";
				this.successful = true;
				this.stopLoading();
				this.loadForms();
			},
			error => {
				this.successful = false;
				this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
				this.stopLoading();
			}
		);
	}
}
