import { render, staticRenderFns } from "./Individual1099Forms.vue?vue&type=template&id=206d28aa&scoped=true&"
import script from "./Individual1099Forms.vue?vue&type=script&lang=ts&"
export * from "./Individual1099Forms.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "206d28aa",
  null
  
)

export default component.exports